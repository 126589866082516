import React from "react";

export const SidebarData = [
  {
    title: "Coach Management",
    path: "/coach-management",
    icon: <i class="fa-solid fa-users"></i>,
  },
  {
    title: "Program Management",
    path: "/program-management",
    icon: <i class="fa-solid fa-book-open"></i>,
  },
  {
    title: "Program Videos",
    path: "/program-videos",
    icon: <i class="fa-solid fa-video"></i>,
  },
  {
    title: "Thought of the Day",
    path: "/thought-of-the-day",
    icon: <i class="fa-solid fa-brain"></i>,
  },
  {
    title: "All Programs",
    path: "/all-programs",
    icon: <i class="fa-solid fa-list-check"></i>,
  },
];
