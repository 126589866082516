import React, { useRef, useState } from "react";
import { Modal } from "../../components/Modal";
import { SlClose } from "react-icons/sl";
import { Field, Form, Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import FileVideo from "../../components/FileVideo";
import { handleVideoUpload } from "../../utils/functions";
import Input from "../../components/Input";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "../../axios";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core";
import Overlay from "../../components/Overlay";

const useStyles = makeStyles((theme) => ({
  textMiddle: {
    verticalAlign: "middle !important",
    textAlign: "center",
  },
}));

function AddCourse(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async (values) => {
    try {
      setIsLoading(true)
      const formData = new FormData();
  
      // Append program title
      formData.append("title", values.course_name);
  
      // Append videos
      if (values.video) {
        for (let i = 0; i < values.video.length; i++) {
          formData.append(`video${i}`, values.video[i]);
        }
      }
  
      // Append documents
      if (values.programDocument) {
        for (let i = 0; i < values.programDocument.length; i++) {
          formData.append(`PPT${i}`, values.programDocument[i]);
        }
      }
  
      const token = localStorage.getItem("token");
      const response = await axios.post("/CreateallProgram", formData, {
        headers: {
          Authorization: token,
        },
      });
  
      if (response.status === 200) {
        toast.success("New Program Created Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        props.setOpenModal(false)
      setIsLoading(false)

      } else {
        throw new Error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error creating new program:", error);
      // Handle error here, such as displaying an error message
    }
  };
  
  return (
    <Modal
      maxWidth="lg"
      width="700px"
      height="auto"
      RoundedCorners={true}
      isOpen={props.openModal}
      onClose={(event, reason) => {
        if (reason && (reason === "backdropClick" || "escapeKeyDown")) {
        } else {
          props.setOpenModal(false);
        }
      }}
      backgroundModal={false}
      backgroundModalContent={false}
      title={
        <div>
          <div
            className="my-3"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "22px",
              fontFamily: "'DM Sans', sans-serif",
            }}
          >
            {"Add New Program"}
          </div>
          <div className="">
            <SlClose
              style={{
                position: "absolute",
                top: 16,
                right: 16,
                cursor: "pointer",
              }}
              onClick={() => {
                props.setOpenModal(false);
              }}
            />
          </div>
        </div>
      }
      content={
        <>
          <Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={(values) => {
              submitForm(values);
            }}
          >
            {(formikBag) => (
              <Form>
                <div className="signup-cont row">
                  <div className="col-md-12">
                    <label className={classes.offerLabel}>Program Title</label>
                    <Field name="Enter Program Title">
                      {({ field }) => (
                        <div className="pb-2 mt-1">
                          <Input
                            {...field}
                            type="text"
                            variant="outlined"
                            
                            value={formikBag.values.course_name}
                            onChange={(e) => {
                              formikBag.setFieldValue(
                                "course_name",
                                e.target.value
                              );
                            }}
                            className="form-control"
                            placeholder="Program Title"
                          />
                        </div>
                      )}
                    </Field>
                  </div>

                  <div className="col-md-12">
                    <label className={classes.offerLabel}>Program Videos</label>
                    <Field name="Enter Email">
                      {({ field }) => (
                        <div className="pb-2 mt-1">
                          <input
                            {...field}
                            type="file"
                            accept="video/*" // Restrict file selection to video files
                            multiple
                            onChange={(e) => {
                              formikBag.setFieldValue(
                                "video",
                                e.currentTarget.files
                              );
                            }}
                            style={{ height: "100%" }}
                            error={
                              formikBag.touched.video && formikBag.errors.video
                                ? formikBag.errors.video
                                : null
                            }
                            className="form-control"
                            placeholder="Program Video"
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="col-md-12">
                    <label className={classes.offerLabel}>
                      Program Documents
                    </label>
                    <Field name="Enter Documents">
                      {({ field }) => (
                        <div className="pb-2 mt-1">
                          <input
                            {...field}
                            type="file"
                            multiple
                            accept=".pdf, .ppt, .pptx, .doc, .docx" // Accept PDF, PPT, PPTX, DOC, and DOCX files
                            onChange={(e) => {
                              formikBag.setFieldValue(
                                "programDocument",
                                e.currentTarget.files
                              );
                            }}
                            style={{ height: "100%" }}
                            error={
                              formikBag.touched.programDocument &&
                              formikBag.errors.programDocument
                                ? formikBag.errors.programDocument
                                : null
                            }
                            className="form-control"
                            placeholder="Program Document"
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="row mt-3">
                  <div
                    className="col-md-12"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ padding: "0.5em 2em" }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          {isLoading && <Overlay />}
        </>
      }
    />
  );
}

export default AddCourse;
