import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import {
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import LoginSection from "./components/LoginSection";
import Navbar from "./components/Navbar";
import Sidebar from "./components/SidebarHaydii/Sidebar";
import "./index.css";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import UserManagement from '../src/pages/User Management/UserManagement';
import ProgramManagement from "./pages/Program Management/ProgramManagement";
import * as actionTypes from "./store/actions";
import ProgramVideos from "./pages/ProgramVideos/ProgramVideos";

import Thought from "./pages/Thought/Thought";
import AllPrograms from "./pages/All Programs/AllPrograms";




const PublicRoute = (props) => {
  const { defaultState, setDefaultState } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  console.log("public route");
  return (
    <Switch>
      <Route path="/" exact>
        <LoginSection {...defaultState} />
      </Route>
      <Route path="/">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

const PrivateRoute = (props) => {
  const { defaultState, setDefaultState, userData } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Switch>
      <Route path="/coach-management" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
       <UserManagement/>
      </Route>
      
      <Route path="/program-management" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
       <ProgramManagement/>
      </Route>
      <Route path="/program-videos" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
       <ProgramVideos/>
      </Route>
      <Route path="/thought-of-the-day" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
       <Thought/>
      </Route>
      <Route path="/all-programs" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
       <AllPrograms/>
      </Route>
      <Route exact path="/">
        <LoginSection {...defaultState} />
      </Route>
    </Switch>
  );
};

function App(props) {
  const { defaultState, setDefaultState, userData } = props;
  const [userToken,setUserToken] = useState(true)
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const token = localStorage.getItem("token");

  return (
    <div>
      {userToken === true ? <PrivateRoute /> : <PublicRoute />}
      <ToastContainer theme="colored" />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    locationData: state.locations,
    defaultState: state.defaultState,
    userData: state.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setLocations: (updatedValue) => {
      dispatch({
        type: actionTypes.GET_LOCATIONS,
        locationData: updatedValue,
      });
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
