import axios from "../axios";
import Axios from "axios";
import { useState } from "react";
export const uploadImage = async (file) => {
  var formData = new FormData();
  formData.append("media", file);
  try {
    const { data } = await axios.post("/auth/uploadImage", formData);

    return data;
  } catch (error) {}
};

export const extractDate = (e) => {
  if (e) {
    let date = new Date(e).toLocaleDateString();
    return date;
  } else {
    return "";
  }
};

export async function handleVideoUpload(file) {
  try {
    const formData = new FormData();
    formData.append("media", file);
    console.log(file);
    const config = {
      baseURL: "http://13.126.178.112:3000/media/upload",
      headers: {
        "Content-Type": "multipart/form-data",
        token: localStorage.getItem("token"),
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 50;
        // Update progress state or perform any other actions
      },
      onDownloadProgress: (progressEvent) => {
        const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
        // Update progress state or perform any other actions
      },
    };

    // Perform the upload
    const response = await axios.post("", formData, config);
    // Assuming the response contains the URL of the uploaded video
    const videoUrl = response.data.url;
    return videoUrl;
  } catch (error) {
    console.error("Error uploading video:", error);
    throw error; // Propagate the error to the caller
  }
}

export async function handleImageUpload(file) {
  // const [progress, setProgress] = useState(0);
  const formData = new FormData();
  formData.append("media", file);
  console.log(file);
  const config = {
    baseURL: "https://smartcarrots.com",
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    },
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 50;
      // setProgress(progress);
      // console.log(progressEvent.loaded);
      // console.log(progress);
    },
    onDownloadProgress: (progressEvent) => {
      const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
      // console.log(progress);
      // setProgress(progress);
    },
  };

  try {
    const { data } = await Axios.post("/user/uploadImage", formData, config);
    // console.log(data);

    return data.path;
    //{ location: data.result.file, };
  } catch (err) {
    return err;
  }
}
