import {
  Button,
  Checkbox,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  styled,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  SearchBar,
  SearchContainer,
  SearchIcon,
  SearchInput,
} from "../../components/SearchBar/SearchElements";
import Dummy from "../../images/dummy-img.png";
import { handleImageUpload } from "../../utils/functions";
import "./AddEditBlog.css";
import {
  DashHeading,
  DashboardContainer,
  DashboardHeading,
  DashboardWrapper,
  HeadingButton,
  MenuAndBack,
} from "./BlogElements";
// import { extractDate } from "../../utils/functions";
import axios from "../../axios";
import Overlay from "../../components/Overlay";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actionTypes from "../../store/actions";
import { DeleteOutline } from "@material-ui/icons";
// import { uploadImage } from "../../utils/functions";
import { CategoryTwoTone, Edit } from "@material-ui/icons";
import { get } from "lodash";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import { BsFilter } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { SlClose } from "react-icons/sl";
import FileInput from "../../components/FileInput";
import Input from "../../components/Input";
import { Modal } from "../../components/Modal";
import Nodata from "../../components/Nodata";
import { CoachDataValidator } from "../../utils/validators";
import { toast } from "react-toastify";
import Select from "react-select";
const useStyles = makeStyles((theme) => ({
  textMiddle: {
    verticalAlign: "middle !important",
    textAlign: "center",
  },
  tablseHeadingCell: {
    textAlign: "center",
    fontWeight: "600",
  },
  tablePadding: {
    padding: "5px",
    textAlign: "center",
    fontSize: "0.8rem",
    fontWeight: "800",
  },
  tableContainerHeight: {
    height: "70vh",
  },
  paperTableHeight: {
    height: "650px",
    width: "95%",
    marginLeft: "2rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  "@media (max-width: 780px)": {
    paperTableHeight: {
      marginLeft: "0.75rem",
    },
    tableContainerHeight: {
      maxHeight: "64vh",
    },
  },
  "@media (max-width: 968px)": {
    tableContainerHeight: {
      maxHeight: "64vh",
    },
  },
  "@media (max-width: 480px)": {
    paperTableHeight: {
      marginLeft: "0.75rem",
    },
  },
  tablePaginationStyle: {
    border: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  tableFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  searchDesign: {
    borderRadius: "20px",
    boxShadow: "none",
    width: "21%",
  },
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#1692B4" : "red",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "red",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "red" : "#1692B4",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const OfferManagement = ({ history, setUsers, userData }) => {
  // console.log("jsdjsjdsjdjsdjsdsd", history);
  const classes = useStyles();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(90);
  const [searchedData, setSearchedData] = useState([]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [coachData, setCoachData] = useState({
    id: "",
    name: "",
    mobile_no: "",
    email: "",
    password: "",
    profile_image: "",
    Designation: "",
    level: null,
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const options = [
    { value: "GOLD", label: "Gold" },
    { value: "SILVER", label: "Silver" },
    { value: "PLATINUM", label: "Platinum" },
  ];

  useEffect(() => {
    getCoachList();
  }, []);

  // For Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [openCModal, setOpenCModal] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const recordsAfterPagingAndSorting = () => {
    const result = [];
    for (let i = 0; i <= tableData.length - 1; i++) {
      result.push(tableData[i]);
    }
    const sortedData = stableSort(result, getComparator(order, orderBy));

    // Calculate the starting index and ending index for the current page
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    // Return the subset of data for the current page
    return sortedData.slice(startIndex, endIndex);
  };

  const handleSortRequest = (cellId) => {
    console.log(cellId);
    console.log(orderBy);
    const isAsc = orderBy === cellId && order === "asc";
    // stableSort(tableData, getComparator(order, cellId))
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    // console.log(stabilizedThis.map((el) => el[0]));
    return stabilizedThis.map((el) => el[0]);
  };

  function getComparator(order, orderBy) {
    // return  (a, b) => descendingComparator(a, b, orderBy)
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return 1;
    }
    if (b[orderBy] > a[orderBy]) {
      return -1;
    }
    return 0;
  }

  const dateOfJoining = (e) => {
    var date = new Date(e).toLocaleDateString();
    return date;
  };
  const dateOfExpiry = (e) => {
    var date = new Date(e).toLocaleDateString();
    if (e) {
      return date;
    } else {
      return "N/A";
    }
  };
  const getCoachList = async (
    page = 1,
    rowsPerPage = 15,
    searchValue = "",
    startDate = "",
    endDate = "",
    dateFilter = ""
  ) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.get(
        `/getAllCoach?page=${page}&pageSize=${rowsPerPage}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setTableData(data.data);
      setSearchedData(data.data);
      console.log(data.data);
      setTotalData(data.data.length);
      setPage(page - 1);
      setRowsPerPage(rowsPerPage);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      // if (err.response.status === 401 || err.response.status === 500) {
      //   localStorage.removeItem("token");
      //   localStorage.removeItem("userData");
      // }
    }
  };
  const [courseData, setCourseData] = useState([]);
  const getCourseList = async () => {
    getCoachList();
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.get(`/getAllCourse`, {
        headers: {
          Authorization: token,
        },
      });
      setCourseData(data.data);
      console.log(data.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      // if (err.response.status === 401 || err.response.status === 500) {
      //   localStorage.removeItem("token");
      //   localStorage.removeItem("userData");
      // }
    }
  };
  const [checkedItems, setCheckedItems] = useState([]);
  const handleCheckboxChange = (course_id) => {
    console.log(course_id, "new array");
    setCheckedItems((prevState) => {
      const isChecked =
        Array.isArray(prevState) && prevState.includes(course_id);
      if (!isChecked) {
        return [...prevState, course_id];
      } else {
        const newArray = Array.isArray(prevState)
          ? prevState.filter((id) => id !== course_id)
          : [];
        return newArray;
      }
    });
  };
  const [email, setEmail] = useState();
  const assignCourse = async () => {
    const filteredArray = Array.isArray(checkedItems)
      ? checkedItems.filter((element) => typeof element === "number")
      : [];
    console.log(filteredArray);
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.post(
        `/AssignCourseByemail/${email}`,
        {
          courses_id: filteredArray,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success('Course assigned successfully', {
        position: toast.POSITION.TOP_RIGHT,
      });
      getCoachList();
      setOpenCModal(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  function myDeb(call, d = 1000) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, d);
    };
  }

  const SearchUser = (searchedVal) => {
    const filteredRows = searchedData.filter((row) => {
      let phone = row.mobile_no;
      let email = row.email;
      let name = row.name;

      return (
        phone.toLowerCase().includes(searchedVal.target.value.toLowerCase()) ||
        email.toLowerCase().includes(searchedVal.target.value.toLowerCase()) ||
        name.toLowerCase().includes(searchedVal.target.value.toLowerCase())
      );
    });
    setTableData(filteredRows);
  };

  const handleSubmit = async (values) => {
    try {
      const data = await axios.post("/createCoach", values);
      toast.success("New Coach Created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setOpenModal(false);
      getCoachList();
    } catch (error) {
      toast.error("Email or Phone already exists", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const deleteCoach = async (e) => {
    // Prompt the user to enter the admin password
    const adminPassword = prompt("Enter admin password:");

    // Check if the admin password is correct
    if (adminPassword === "123456") {
      if (window.confirm("Are you sure you want to delete this coach?")) {
        try {
          const token = localStorage.getItem("token");

          const { data } = await axios.delete(
            `/deletCoachbyID/${e.categoryId}`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          getCoachList();
          toast.error("Coach deleted Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      // If the admin password is incorrect, show an error message
      alert("Incorrect admin password. Deletion canceled.");
    }
  };

  return (
    <>
      <div>
        <DashboardContainer>
          <DashboardWrapper>
            <DashboardHeading
              style={{ display: "flex", flexDirection: "column" }}
            >
              <MenuAndBack
                style={{
                  backgroundColor: "#1692B4",
                  width: "100%",
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i
                  class="fa-solid fa-users"
                  style={{ fontSize: "25px", margin: "8px" }}
                ></i>

                <DashHeading
                  style={{ color: "white", flex: "1", padding: "8px" }}
                >
                  Coach Management
                </DashHeading>
              </MenuAndBack>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ width: "100%" }}
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ width: "100%" }}
                >
                  <SearchContainer style={{ width: "100%" }}>
                    <SearchBar style={{ width: "350px" }}>
                      <SearchIcon>
                        <FaSearch style={{ color: "#c4c4c4" }} />
                      </SearchIcon>
                      <SearchInput
                        type="text"
                        onChange={(searchVal) => SearchUser(searchVal)}
                        placeholder="Search by Email, Phone Number & Name"
                      ></SearchInput>
                    </SearchBar>
                  </SearchContainer>
                </div>

                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    background: "#1692B4",
                    border: "#1692B4",
                    width: "150px",
                    height: "50px",
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    setOpenModal(true);
                    setCoachData({
                      id: "",
                      name: "",
                      mobile_no: "",
                      email: "",
                      password: "",
                      profile_image: "",
                      Designation: "",
                    });
                  }}
                >
                  ADD COACH
                </button>
              </div>
            </DashboardHeading>

            <Paper
              className={classes.paperTableHeight}
              style={{
                overflow: "hidden",
                height: "100%",
                marginBottom: "0.5rem",
              }}
            >
              <TableContainer className={classes.tableContainerHeight}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tablePadding}
                        style={{ fontWeight: "800" }}
                      >
                        S.&nbsp;No.
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Date
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Image
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Name
                      </TableCell>

                      <TableCell className={classes.tablePadding}>
                        Email
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Level
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Designation
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Phone Number
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {recordsAfterPagingAndSorting().map((category, index) => (
                      <TableRow key={category.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.textMiddle}
                        >
                          {index + 1 + page * rowsPerPage}
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          {moment(get(category, "date_joined", "")).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          <div>
                            {get(category, "profile_image", "") !== "" ? (
                              <img
                                src={get(category, "profile_image", "")}
                                height={40}
                                width={40}
                                className="rounded-circle"
                                alt="profile"
                              />
                            ) : (
                              <img
                                src={Dummy}
                                height={40}
                                width={40}
                                alt="dummy"
                                className="rounded-circle"
                              />
                            )}
                          </div>
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          <div>{get(category, "name", "")}</div>
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          <div> {get(category, "email", "")}</div>
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          <div> {get(category, "level", "")}</div>
                        </TableCell>

                        <TableCell className={classes.textMiddle}>
                          {get(category, "Designation", "")}
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          <div>{get(category, "mobile_no", "")}</div>
                        </TableCell>
                        <TableCell
                          className={`${classes.textMiddle} d-flex justify-content-center align-items-center`}
                          style={{ marginTop: "10px" }}
                        >
                          <Button
                            style={{
                              padding: "3px 14px",
                              textTransform: "capitalize",
                              borderRadius: "15px",
                              color:
                                JSON.parse(get(category, "Course_id")).length >
                                0
                                  ? "#000"
                                  : "#fff",
                              backgroundColor:
                                JSON.parse(get(category, "Course_id")).length >
                                0
                                  ? "#dddbd7"
                                  : "#1692b4",
                            }}
                            variant="contained"
                            onClick={() => {
                              setOpenCModal(true);
                              getCourseList();
                              setEmail(get(category, "email"));
                              const courseIdString = get(category, "Course_id");
                              if (courseIdString) {
                                try {
                                  const parsedCourseIds =
                                    JSON.parse(courseIdString);
                                  // Check if parsedCourseIds is an array
                                  if (Array.isArray(parsedCourseIds)) {
                                    setCheckedItems(parsedCourseIds);
                                  } else {
                                    console.error(
                                      "Parsed data is not an array:",
                                      parsedCourseIds
                                    );
                                  }
                                } catch (error) {
                                  console.error(
                                    "Error parsing JSON data:",
                                    error
                                  );
                                }
                              } else {
                                console.error(
                                  "Course_id is empty or undefined"
                                );
                              }
                            }}
                          >
                            {JSON.parse(get(category, "Course_id", "")).length >
                            0
                              ? "Update Course"
                              : "Assign Course"}
                          </Button>
                          <Tooltip title={"Delete"} arrow>
                            <Button
                              // variant="outlined"
                              aria-label="delete"
                              // className={classes.Marginbutton}
                              onClick={() => {
                                deleteCoach({ categoryId: category.id });
                              }}
                            >
                              <DeleteOutline />
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {tableData.length === 0 ? (
                <Nodata TextToDisplay="No Data Found." fontSize="24px" />
              ) : (
                false
              )}
              <TablePagination
                className={classes.tablePaginationStyle}
                rowsPerPageOptions={[15, 30, 100]}
                component="div"
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </DashboardWrapper>
        </DashboardContainer>
      </div>
      <Modal
        maxWidth="lg"
        width="700px"
        height="auto"
        RoundedCorners={true}
        isOpen={openCModal}
        onClose={(event, reason) => {
          if (reason && (reason === "backdropClick" || "escapeKeyDown")) {
          } else {
            setOpenCModal(false);
          }
        }}
        backgroundModal={false}
        backgroundModalContent={false}
        title={
          <div>
            <div
              className="my-3"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "22px",
                fontFamily: "'DM Sans', sans-serif",
              }}
            >
              {"Assign Course to Coach"}
            </div>
            <div className="">
              <SlClose
                style={{
                  position: "absolute",
                  top: 16,
                  right: 16,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenCModal(false);
                }}
              />
            </div>
          </div>
        }
        content={
          <>
            <Formik enableReinitialize validateOnChange>
              {(formikBag) => {
                return (
                  <Form>
                    <div className="signup-cont">
                      <div className="row">
                        <div className="col-md-12">
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    className={classes.tablseHeadingCell}
                                  >
                                    S. No.
                                  </TableCell>
                                  <TableCell
                                    className={classes.tablseHeadingCell}
                                  >
                                    Course Id
                                  </TableCell>
                                  <TableCell
                                    className={classes.tablseHeadingCell}
                                  >
                                    Course Name
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {courseData !== undefined &&
                                  courseData.map((course, index) => (
                                    <TableRow key={course.course_id}>
                                      <TableCell
                                        className={classes.textMiddle}
                                        component="th"
                                        scope="row"
                                      >
                                        {index + 1 + page * rowsPerPage}
                                      </TableCell>

                                      <TableCell
                                        className={`${classes.textMiddle} d-flex justify-content-center align-items-center`}
                                      >
                                        <Checkbox
                                          checked={
                                            Array.isArray(checkedItems)
                                              ? checkedItems.includes(
                                                  course.course_id
                                                )
                                              : false
                                          }
                                          onChange={() =>
                                            handleCheckboxChange(
                                              course.course_id
                                            )
                                          }
                                          color="primary"
                                        />
                                        <div className="d-flex align-items-center">
                                          {get(course, "course_id", "")}
                                        </div>
                                      </TableCell>
                                      <TableCell className={classes.textMiddle}>
                                        <div className="">
                                          <div>
                                            {get(course, "course_name", "")}
                                          </div>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                {courseData === undefined && (
                                  <Nodata
                                    TextToDisplay="No Course Found."
                                    fontSize="24px"
                                  />
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div
                        className="col-md-12"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <HeadingButton
                          type="button"
                          onClick={assignCourse}
                          style={{ padding: "0.5em 2em" }}
                        >
                          Save
                        </HeadingButton>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </>
        }
      />
      <Modal
        maxWidth="lg"
        width="700px"
        height="auto"
        RoundedCorners={true}
        isOpen={openModal}
        // RoundedCorners={true}
        onClose={(event, reason) => {
          if (reason && (reason === "backdropClick" || "escapeKeyDown")) {
          } else {
            setOpenModal(false);
            setCoachData({
              id: "",
              name: "",
              mobile_no: "",
              email: "",
              password: "",
              profile_image: "",
              Designation: "",
              level: "",
            });
          }
        }}
        backgroundModal={false}
        backgroundModalContent={false}
        title={
          <div>
            <div
              className="my-3"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "22px",
                fontFamily: "'DM Sans', sans-serif",
              }}
            >
              {"Add New Coach"}
            </div>
            <div className="">
              <SlClose
                style={{
                  position: "absolute",
                  top: 16,
                  right: 16,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenModal(false);
                  setCoachData({
                    id: "",
                    name: "",
                    mobile_no: "",
                    email: "",
                    password: "",
                    profile_image: "",
                    Designation: "",
                    level: "",
                  });
                }}
              />
            </div>
          </div>
        }
        content={
          <>
            <Formik
              key={coachData}
              enableReinitialize
              initialValues={coachData}
              validate={CoachDataValidator}
              validateOnChange
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {(formikBag) => {
                return (
                  <Form>
                    {console.log(formikBag.values)}
                    <div className="signup-cont">
                      <div className="row">
                        <div className="col-md-6">
                          <label className={classes.offerLabel}>
                            Coach Name
                          </label>
                          <Field name="Enter Name">
                            {({ field }) => (
                              <div className="pb-2 mt-1">
                                <Input
                                  {...field}
                                  type="text"
                                  variant="outlined"
                                  autocomplete="off"
                                  value={formikBag.values.name}
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "name",
                                      e.target.value
                                    );
                                  }}
                                  error={
                                    formikBag.touched.name &&
                                    formikBag.errors.name
                                      ? formikBag.errors.name
                                      : null
                                  }
                                  className="form-control"
                                  placeholder="Coach Name"
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="col-md-6">
                          <label className={classes.offerLabel}>
                            Coach Email
                          </label>
                          <Field name="Enter Email">
                            {({ field }) => (
                              <div className="pb-2 mt-1">
                                <Input
                                  {...field}
                                  type="text"
                                  variant="outlined"
                                  value={formikBag.values.email}
                                  autocomplete="off"
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "email",
                                      e.target.value
                                    );
                                  }}
                                  error={
                                    formikBag.touched.email &&
                                    formikBag.errors.email
                                      ? formikBag.errors.email
                                      : null
                                  }
                                  className="form-control"
                                  placeholder="Coach Email"
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="col-md-6">
                          <label className={classes.offerLabel}>
                            Coach Level
                          </label>
                          <div className="mt-2">
                            <Select
                              options={options}
                              value={options.find(
                                (option) =>
                                  option.value === formikBag.values.level
                              )}
                              onChange={(selectedOption) => {
                                formikBag.setFieldValue(
                                  "level",
                                  selectedOption.value
                                );
                              }}
                              classNamePrefix="react-select"
                              isClearable
                              placeholder="Select Coach Level"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label className={classes.offerLabel}>
                            Coach Designation
                          </label>
                          <Field name="Coach Designation">
                            {({ field }) => (
                              <div className="pb-2 mt-1">
                                <Input
                                  {...field}
                                  type="text"
                                  variant="outlined"
                                  autocomplete="off"
                                  value={formikBag.values.Designation}
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "Designation",
                                      e.target.value
                                    );
                                  }}
                                  error={
                                    formikBag.touched.Designation &&
                                    formikBag.errors.Designation
                                      ? formikBag.errors.Designation
                                      : null
                                  }
                                  className="form-control"
                                  placeholder="Coach Designation"
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="col-md-6">
                          <label className={classes.offerLabel}>
                            Coach Phone Number
                          </label>
                          <Field name="Coach Phone Number">
                            {({ field }) => (
                              <div className="pb-2 mt-1">
                                <Input
                                  {...field}
                                  type="text"
                                  variant="outlined"
                                  autocomplete="off"
                                  value={formikBag.values.mobile_no}
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "mobile_no",
                                      e.target.value
                                    );
                                  }}
                                  error={
                                    formikBag.touched.mobile_no &&
                                    formikBag.errors.mobile_no
                                      ? formikBag.errors.mobile_no
                                      : null
                                  }
                                  className="form-control"
                                  placeholder="Coach Phone Number"
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="col-md-6">
                          <label className={classes.offerLabel}>
                            Coach Password
                          </label>
                          <Field name="Coach Password">
                            {({ field }) => (
                              <div className="pb-2 mt-1">
                                <Input
                                  {...field}
                                  type="password"
                                  variant="outlined"
                                  autocomplete="off"
                                  value={formikBag.values.password}
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "password",
                                      e.target.value
                                    );
                                  }}
                                  error={
                                    formikBag.touched.password &&
                                    formikBag.errors.password
                                      ? formikBag.errors.password
                                      : null
                                  }
                                  className="form-control"
                                  placeholder="Coach Password"
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="col-md-12 mt-2">
                          <label>Coach Image</label>
                          <Field name="image">
                            {({ field }) => (
                              <div className="py-2">
                                <FileInput
                                  className="file-input"
                                  id="facility_images"
                                  limit="1"
                                  dictionary="dictionary"
                                  images={
                                    formikBag.values.profile_image
                                      ? [formikBag.values.profile_image]
                                      : []
                                  }
                                  onDelete={(image) => {
                                    formikBag.setFieldValue(
                                      "profile_image",
                                      ""
                                    );
                                  }}
                                  type="text"
                                  label="upload_products_facility_photos"
                                  info="eg_img"
                                  onChange={async (e) => {
                                    const fileSize =
                                      e.target.files[0].size / 1024 / 1024; // in MiB
                                    if (fileSize > 2) {
                                      alert("ex_2mb");
                                    } else {
                                      var profile_image =
                                        await handleImageUpload(
                                          e.target.files[0]
                                        );
                                      // console.log("this is image url", image);
                                      formikBag.setFieldValue(
                                        "profile_image",
                                        profile_image
                                      );
                                    }
                                  }}
                                />
                                {formikBag.touched.profile_image &&
                                  formikBag.errors.profile_image && (
                                    <div
                                      style={{
                                        color: "red",
                                        textAlign: "center",
                                      }}
                                    >
                                      {formikBag.errors.profile_image}
                                    </div>
                                  )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div
                        className="col-md-12"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <HeadingButton
                          type="submit"
                          style={{ padding: "0.5em 2em" }}
                        >
                          Save
                        </HeadingButton>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </>
        }
      />
      {isLoading && <Overlay />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OfferManagement));
