import React, { useRef, useState } from "react";
import { Modal } from "../../components/Modal";
import { SlClose } from "react-icons/sl";
import { Field, Form, Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import FileVideo from "../../components/FileVideo";
import Input from "../../components/Input";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "../../axios";
import { toast } from "react-toastify";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textMiddle: {
    verticalAlign: "middle !important",
    textAlign: "center",
  },
}));

export const WeekAccordionDetails = ({
  index,
  fileVideoRefs,
  videos,
  setVideos,
  pptPdfs,
  setPptPdfs,
  weekHeadings,
  setWeekHeadings,
  weekSubheadings,
  setWeekSubheadings,
  fileInputRefs,
}) => {
  const handleVideoFileChange = (e) => {
    const videoFile = e.target.files[0];
    setVideos((prevVideos) => {
      const newVideos = [...prevVideos];
      const index = newVideos.findIndex((video) => video === "");
      if (index !== -1) {
        newVideos[index] = videoFile;
      }
      return newVideos;
    });
  };

  const handlePptPdfFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      if (
        file.type === "application/pdf" ||
        file.type === "application/vnd.ms-powerpoint" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        const newPptPdfs = [...pptPdfs];
        newPptPdfs[index] = file;
        setPptPdfs(newPptPdfs);
      } else {
        alert("Please select a PDF or PowerPoint file.");
      }
    } else {
      console.log("No file selected.");
    }
  };

  const handleHeadingChange = (e) => {
    const newHeadings = [...weekHeadings];
    newHeadings[index].value = e.target.value;
    setWeekHeadings(newHeadings);
  };

  const handleSubHeadingChange = (e, subIndex) => {
    const newSubHeadings = [...weekSubheadings];
    if (newSubHeadings[index]) {
      // Ensure weekSubheadings[index] is defined
      newSubHeadings[index][subIndex].value = e.target.value;
      setWeekSubheadings(newSubHeadings);
    }
  };

  const addSubHeading = () => {
    const newSubHeadings = [...weekSubheadings];
    if (!newSubHeadings[index]) newSubHeadings[index] = []; // Initialize if undefined
    newSubHeadings[index].push({
      id: weekSubheadings[index].length + 1,
      value: "",
    });
    setWeekSubheadings(newSubHeadings);
  };

  const removeSubHeading = () => {
    const newSubHeadings = [...weekSubheadings];
    if (newSubHeadings[index]) {
      // Ensure weekSubheadings[index] is defined
      newSubHeadings[index].pop();
      setWeekSubheadings(newSubHeadings);
    }
  };

  return (
    <AccordionDetails className="row" style={{ margin: "10px" }}>
      <div
        className="d-flex flex-column"
        style={{
          width: "100%",
          borderBottom: "1px solid gray",
          paddingBottom: "20px",
        }}
      >
        <div className="d-flex row">
          <div className="d-flex flex-column" style={{ width: "100%" }}>
            <div className="d-flex row">
              <div className="col-md-6 d-flex flex-column justify-content-center align-items-center mt-2">
                <label
                  className="d-flex justify-content-center"
                  style={{ width: "100%" }}
                >
                  Course Video
                </label>
                <input
                  type="file"
                  accept="video/*"
                  ref={fileVideoRefs.current[index]}
                  onChange={handleVideoFileChange}
                  style={{ display: "none" }}
                />
                <div className="py-2 mb-3">
                  <div className="file-input" style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{ width: "180px" }}
                      startIcon={<CloudUploadIcon />}
                      onClick={() => {
                        fileVideoRefs.current[index].current.click();
                      }}
                    >
                      Choose Video
                    </Button>
                    {videos[index] && (
                      <div className="mt-2">
                        <span>Selected file: {videos[index].name}</span>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => {
                            const newVideos = [...videos];
                            newVideos[index] = "";
                            setVideos(newVideos);
                            fileVideoRefs.current[index].current.value = "";
                          }}
                          className="ml-2"
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center align-items-center mt-1">
                <label
                  htmlFor={`ppt-pdf-upload-${index + 1}`}
                  style={{ textAlign: "center" }}
                >
                  Course PPT/PDF
                </label>
                <input
                  id={`ppt-pdf-upload-${index + 1}`}
                  type="file"
                  accept=".pdf,.ppt,.pptx"
                  style={{ display: "none" }}
                  ref={fileInputRefs.current[index]}
                  onChange={handlePptPdfFileChange}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className="mb-3"
                  style={{ width: "160px" }}
                  component="span"
                  startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    fileInputRefs.current[index].current.click();
                  }}
                >
                  Choose File
                </Button>
                {pptPdfs[index] && (
                  <div className="mt-2">
                    <span>Selected file: {pptPdfs[index].name}</span>
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        const newPptPdfs = [...pptPdfs];
                        newPptPdfs[index] = null;
                        setPptPdfs(newPptPdfs);
                        fileInputRefs.current[index].current.value = "";
                      }}
                      className="ml-2"
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <label>Heading</label>
          <div className="pb-2 mt-1">
            <Input
              type="text"
              variant="outlined"
              className="form-control"
              placeholder="Heading"
              value={weekHeadings[index].value}
              onChange={handleHeadingChange}
            />
          </div>
        </div>
        {weekSubheadings[index]?.map(
          (
            subHeading,
            subIndex // Use optional chaining to avoid accessing properties of undefined
          ) => (
            <div key={subIndex} className="col-md-12">
              <label>Sub Heading {subIndex + 1}</label>
              <div className="pb-2 mt-1">
                <Input
                  type="text"
                  variant="outlined"
                  className="form-control"
                  placeholder="Sub Heading"
                  value={subHeading?.value}
                  onChange={(e) => handleSubHeadingChange(e, subIndex)}
                />
              </div>
            </div>
          )
        )}
        <div className="w-full d-flex align-items-center col-md-12">
          <Button
            variant="contained"
            color="secondary"
            className="mt-2"
            style={{ width: "220px" }}
            onClick={addSubHeading}
          >
            Add Sub Heading
          </Button>
          {weekSubheadings[index]?.length > 1 && ( // Use optional chaining to avoid accessing properties of undefined
            <Button
              variant="contained"
              color="secondary"
              className="mt-2 ml-2"
              onClick={removeSubHeading}
              style={{ width: "220px" }}
            >
              Remove Sub Heading
            </Button>
          )}
        </div>
      </div>
    </AccordionDetails>
  );
};

export const WeekSection = ({ index, handleAddWeek, handleRemoveWeek }) => {
  return (
    <div
      key={index + 1}
      className="d-flex flex-column"
      style={{
        width: "100%",
        borderBottom: "1px solid gray",
        paddingBottom: "20px",
      }}
    >
        <div className="d-flex row">
          <div className="d-flex flex-column" style={{ width: "100%" }}>
            <div className="d-flex row">
              <div className="col-md-6 d-flex flex-column justify-content-center align-items-center mt-2">
                <label
                  className="d-flex justify-content-center"
                  style={{ width: "100%" }}
                >
                  Course Video
                </label>
                <input
                  type="file"
                  accept="video/*"
                  // ref={}
                  // onChange={}
                  style={{ display: "none" }}
                />
                <div className="py-2 mb-3">
                  <div className="file-input" style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{ width: "180px" }}
                      startIcon={<CloudUploadIcon />}
                      onClick={() => {
                        console.log("hello")
                      }}
                    >
                      Choose Video
                    </Button>
                      <div className="mt-2">
                        <span>Selected file: </span>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => {
                            console.log("hello")
                          }}
                          className="ml-2"
                        >
                          Cancel
                        </Button>
                      </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center align-items-center mt-1">
                <label
                  // htmlFor={`ppt-pdf-upload-${index + 1}`}
                  style={{ textAlign: "center" }}
                >
                  Course PPT/PDF
                </label>
                <input
                  // id={`ppt-pdf-upload-${index + 1}`}
                  type="file"
                  accept=".pdf,.ppt,.pptx"
                  style={{ display: "none" }}
                  // ref={fileInputRefs.current[index]}
                  onChange={console.log("hello")}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className="mb-3"
                  style={{ width: "160px" }}
                  component="span"
                  startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    console.log("hello")
                  }}
                >
                  Choose File
                </Button>
                {/* {pptPdfs[index] && ( */}
                  <div className="mt-2">
                    <span>Selected file: </span>
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        console.log("hello")
                      }}
                      className="ml-2"
                    >
                      Cancel
                    </Button>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <label>Heading</label>
          <div className="pb-2 mt-1">
            <Input
              type="text"
              variant="outlined"
              className="form-control"
              placeholder="Heading"
              // value={weekHeadings[index].value}
              // onChange={handleHeadingChange}
            />
          </div>
        </div>
        
        <div  className="col-md-12">
          <label>Sub Heading </label>
          <div className="pb-2 mt-1">
            <Input
              type="text"
              variant="outlined"
              className="form-control"
              placeholder="Sub Heading"
              // value={subHeading?.value}
              // onChange={(e) => handleSubHeadingChange(e, subIndex)}
            />
          </div>
        </div>
        <div className="w-full d-flex align-items-center col-md-12">
          <Button
            variant="contained"
            color="secondary"
            className="mt-2"
            style={{ width: "220px" }}
            onClick={console.log("hello")}
          >
            Add Sub Heading
          </Button>
            <Button
              variant="contained"
              color="secondary"
              className="mt-2 ml-2"
              // onClick={removeSubHeading}
              style={{ width: "220px" }}
            >
              Remove Sub Heading
            </Button>
        </div>
      </div>
  );
};

function AddCourse(props) {
  const classes = useStyles();
  const fileVideoRefs = useRef(
    Array.from({ length: 12 }, () => React.createRef())
  );
  const fileInputRefs = useRef(
    Array.from({ length: 12 }, () => React.createRef())
  );
  const [videos, setVideos] = useState(Array.from({ length: 1 }, () => ""));
  const [pptPdfs, setPptPdfs] = useState(Array.from({ length: 1 }, () => ""));
  const [weekHeadings, setWeekHeadings] = useState(
    Array.from({ length: 1 }, (_, i) => ({ id: i + 1, value: "" }))
  );
  const [weekSubheadings, setWeekSubheadings] = useState(
    Array.from({ length: 1 }, (_, i) => [])
  );
  const [weekDetails, setWeekDetails] = useState([{ id: 1 }]); // Initialize weekDetails state with at least one object
  const [weekCount, setWeekCount] = useState(1);
  const [visibleWeeks, setVisibleWeeks] = useState([true]);

  const handleAddWeek = () => {
    // const newWeekCount = weekCount + 1;
    // setWeekCount(newWeekCount);
    // setWeekDetails([...weekDetails, { id: newWeekCount }]);

    setVisibleWeeks([...visibleWeeks, true]);
  };

  const handleRemoveWeek = () => {
    // if (weekCount > 1) {
    //   const newWeekCount = weekCount - 1;
    //   setWeekCount(newWeekCount);
    //   setWeekDetails(weekDetails.slice(0, -1));
    // }

    if (visibleWeeks.length > 1) {
      setVisibleWeeks(visibleWeeks.slice(0, -1)); // Remove the last WeekSection by removing the last flag
    }
  };

  const addWeekSection = () => {
    const newWeekHeadings = [
      ...weekHeadings,
      { id: weekHeadings.length + 1, value: "" },
    ];
    const newWeekSubheadings = [...weekSubheadings, []];
    setWeekHeadings(newWeekHeadings);
    setWeekSubheadings(newWeekSubheadings);
  };

  const removeWeekSection = (index) => {
    const newWeekHeadings = weekHeadings.filter((week, i) => i !== index);
    const newWeekSubheadings = weekSubheadings.filter((week, i) => i !== index);
    const newVideos = videos.filter((video, i) => i !== index);
    const newPptPdfs = pptPdfs.filter((pptPdf, i) => i !== index);
    setWeekHeadings(newWeekHeadings);
    setWeekSubheadings(newWeekSubheadings);
    setVideos(newVideos);
    setPptPdfs(newPptPdfs);
    setWeekDetails(weekDetails.slice(0, -1)); // Remove corresponding week details when removing a week section
  };

  const handleNewCourse = async (values) => {
    try {
      const token = localStorage.getItem("token");
      const courseData = {
        course_name: [values.course_name],
        details: weekHeadings
          .filter((weekHeading) => weekHeading.value) // Filter out weeks with no value
          .map((weekHeading, index) => ({
            week: `Week ${index + 1}`,
            details: {
              PPT: pptPdfs[index], // Use pptPdfs[index] for the PPT of the current week
              headings: JSON.stringify({
                heading: [weekHeading.value], // Heading for the current week
                subheading: weekSubheadings[index].map(
                  (subheading) => subheading.value
                ), // Subheadings for the current week
              }),
              video: videos[index], // Use videos[index] for the video of the current week
            },
          })),
      };

      const response = await axios.post("/createCourse", courseData, {
        headers: {
          Authorization: token,
        },
      });

      console.log(response.data);

      props.setOpenModal(false);
    } catch (error) {
      toast.error("Error While Creating Course", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Error:", error);
    }
  };

  return (
    <Modal
      maxWidth="lg"
      width="700px"
      height="auto"
      RoundedCorners={true}
      isOpen={props.openModal}
      onClose={(event, reason) => {
        if (reason && (reason === "backdropClick" || "escapeKeyDown")) {
        } else {
          props.setOpenModal(false);
        }
      }}
      backgroundModal={false}
      backgroundModalContent={false}
      title={
        <div>
          <div
            className="my-3"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "22px",
              fontFamily: "'DM Sans', sans-serif",
            }}
          >
            {"Add New Course"}
          </div>
          <div className="">
            <SlClose
              style={{
                position: "absolute",
                top: 16,
                right: 16,
                cursor: "pointer",
              }}
              onClick={() => {
                props.setOpenModal(false);
              }}
            />
          </div>
        </div>
      }
      content={
        <>
          <Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={(values) => {
              handleNewCourse(values);
            }}
          >
            {(formikBag) => (
              <Form>
                <div className="signup-cont row">
                  <div className="col-md-12">
                    <label className={classes.offerLabel}>Course Name</label>
                    <Field name="Enter Course Name">
                      {({ field }) => (
                        <div className="pb-2 mt-1">
                          <Input
                            {...field}
                            type="text"
                            variant="outlined"
                            value={formikBag.values.course_name}
                            onChange={(e) => {
                              formikBag.setFieldValue(
                                "course_name",
                                e.target.value
                              );
                            }}
                            className="form-control"
                            placeholder="Course Name"
                          />
                        </div>
                      )}
                    </Field>
                  </div>

                  <div className="row">
                    {weekHeadings.map((week, index) => (
                      <div key={index} className="col-md-12">
                        <Accordion
                          style={{ margin: "15px 0px" }}
                          classes={{
                            root: classes.MuiAccordionroot,
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls={`panel${index + 1}-content`}
                            id={`panel${index + 1}-header`}
                          >
                            Session {index + 1}
                          </AccordionSummary>

                          <WeekAccordionDetails
                            index={index}
                            fileVideoRefs={fileVideoRefs}
                            videos={videos}
                            setVideos={setVideos}
                            pptPdfs={pptPdfs}
                            setPptPdfs={setPptPdfs}
                            weekHeadings={weekHeadings}
                            setWeekHeadings={setWeekHeadings}
                            weekSubheadings={weekSubheadings}
                            setWeekSubheadings={setWeekSubheadings}
                            fileInputRefs={fileInputRefs}
                          />
                          {visibleWeeks.map((isVisible, index) => isVisible && <WeekSection key={index} index={index} />)}

                          <div
                            className="w-full d-flex justify-content-end align-items-center"
                            style={{ margin: "2rem" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              type="button"
                              onClick={handleAddWeek}
                            >
                              <AddCircleIcon />
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              type="button"
                              onClick={handleRemoveWeek}
                              style={{ marginLeft: "1rem" }}
                            >
                              <RemoveCircleOutlineIcon />
                            </Button>
                          </div>
                        </Accordion>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row mt-3">
                  <div
                    className="col-md-12"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={addWeekSection}
                      style={{ padding: "0.5em 2em" }}
                    >
                      Add Session
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={() =>
                        removeWeekSection(weekHeadings.length - 1)
                      }
                      style={{ padding: "0.5em 2em", marginLeft: "1rem" }}
                    >
                      Remove Session
                    </Button>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{
                        padding: "0.5em 2em",
                        marginTop: "15px",
                        width: "120px",
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  );
}

export default AddCourse;
