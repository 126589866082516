import {
  Button,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  styled,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Field, Form, Formik } from "formik";
import MoreLess from "../../components/MoreLess";

import React, { useEffect, useRef, useState } from "react";
import {
  SearchBar,
  SearchContainer,
  SearchIcon,
  SearchInput,
} from "../../components/SearchBar/SearchElements";
import Dummy from "../../images/dummy-img.png";
import { handleImageUpload } from "../../utils/functions";
import "./AddEditBlog.css";
import {
  DashHeading,
  DashboardContainer,
  DashboardHeading,
  DashboardWrapper,
  HeadingButton,
  MenuAndBack,
} from "./BlogElements";
// import { extractDate } from "../../utils/functions";
import axios from "../../axios";
import Overlay from "../../components/Overlay";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actionTypes from "../../store/actions";
import { DeleteOutline } from "@material-ui/icons";
// import { uploadImage } from "../../utils/functions";
import { CategoryTwoTone, Edit } from "@material-ui/icons";
import { get } from "lodash";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import { BsFilter } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { SlClose } from "react-icons/sl";
import FileInput from "../../components/FileInput";
import Input from "../../components/Input";
import { Modal } from "../../components/Modal";
import Nodata from "../../components/Nodata";
import { thoughtValidator } from "../../utils/validators";
import { toast } from "react-toastify";
import Select from "react-select";
const useStyles = makeStyles((theme) => ({
  textMiddle: {
    verticalAlign: "middle !important",
    textAlign: "center",
  },
  tablseHeadingCell: {
    textAlign: "center",
    fontWeight: "600",
  },
  tablePadding: {
    padding: "5px",
    textAlign: "center",
    fontSize: "0.8rem",
    fontWeight: "800",
  },
  tableContainerHeight: {
    height: "70vh",
  },
  paperTableHeight: {
    height: "650px",
    width: "95%",
    marginLeft: "2rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  "@media (max-width: 780px)": {
    paperTableHeight: {
      marginLeft: "0.75rem",
    },
    tableContainerHeight: {
      maxHeight: "64vh",
    },
  },
  "@media (max-width: 968px)": {
    tableContainerHeight: {
      maxHeight: "64vh",
    },
  },
  "@media (max-width: 480px)": {
    paperTableHeight: {
      marginLeft: "0.75rem",
    },
  },
  tablePaginationStyle: {
    border: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  tableFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  searchDesign: {
    borderRadius: "20px",
    boxShadow: "none",
    width: "21%",
  },
}));

const OfferManagement = ({ history, setUsers, userData }) => {
  // console.log("jsdjsjdsjdjsdjsdsd", history);
  const classes = useStyles();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(90);
  const [searchedData, setSearchedData] = useState([]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [thought, setThought] = useState({
    thought: "",
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const options = [
    { value: "GOLD", label: "Gold" },
    { value: "SILVER", label: "Silver" },
    { value: "PLATINUM", label: "Platinum" },
  ];

  useEffect(() => {
    getThought();
  }, []);

  // For Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [showFilter, setShowFilter] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const recordsAfterPagingAndSorting = () => {
    const result = [];
    for (let i = 0; i <= tableData.length - 1; i++) {
      result.push(tableData[i]);
    }
    const sortedData = stableSort(result, getComparator(order, orderBy));

    // Calculate the starting index and ending index for the current page
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    // Return the subset of data for the current page
    return sortedData.slice(startIndex, endIndex);
  };

  const handleSortRequest = (cellId) => {
    console.log(cellId);
    console.log(orderBy);
    const isAsc = orderBy === cellId && order === "asc";
    // stableSort(tableData, getComparator(order, cellId))
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    // console.log(stabilizedThis.map((el) => el[0]));
    return stabilizedThis.map((el) => el[0]);
  };

  function getComparator(order, orderBy) {
    // return  (a, b) => descendingComparator(a, b, orderBy)
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return 1;
    }
    if (b[orderBy] > a[orderBy]) {
      return -1;
    }
    return 0;
  }

  const dateOfJoining = (e) => {
    var date = new Date(e).toLocaleDateString();
    return date;
  };
  const dateOfExpiry = (e) => {
    var date = new Date(e).toLocaleDateString();
    if (e) {
      return date;
    } else {
      return "N/A";
    }
  };
  const getThought = async (
    page = 1,
    rowsPerPage = 15,
    searchValue = "",
    startDate = "",
    endDate = "",
    dateFilter = ""
  ) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.get(
        `/getAllThoughts?page=${page}&pageSize=${rowsPerPage}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setTableData(data.data);
      setSearchedData(data.data);
      setTotalData(data.data.length);
      setPage(page - 1);
      setRowsPerPage(rowsPerPage);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      // if (err.response.status === 401 || err.response.status === 500) {
      //   localStorage.removeItem("token");
      //   localStorage.removeItem("userData");
      // }
    }
  };

  const handleSubmit = async (values) => {
    try {
      const token = localStorage.getItem("token");

      const data = await axios.post("/postThoughts", values, {
        headers: {
          Authorization: token,
        },
      });
      toast.success("New Thought Uploaded", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setOpenModal(false);
      getThought();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteThought = async (e) => {
    // Prompt the user to enter the admin password
    const adminPassword = prompt("Enter admin password:");

    // Check if the admin password is correct
    if (adminPassword === "123456") {
      // Replace "your_admin_password" with the actual admin password
      if (window.confirm("Are you sure you want to delete this thought?")) {
        try {
          const token = localStorage.getItem("token");

          const { data } = await axios.delete(
            `/deleteThoughts/${e.categoryId}`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          getThought();
          toast.error("Thought deleted Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      // If the admin password is incorrect, show an error message
      alert("Incorrect admin password. Deletion canceled.");
    }
  };

  return (
    <>
      <div>
        <DashboardContainer>
          <DashboardWrapper>
            <DashboardHeading
              style={{ display: "flex", flexDirection: "column" }}
            >
              <MenuAndBack
                style={{
                  backgroundColor: "#1692B4",
                  width: "100%",
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i
                  class="fa-solid fa-brain"
                  style={{ fontSize: "25px", margin: "8px" }}
                ></i>

                <DashHeading
                  style={{ color: "white", flex: "1", padding: "8px" }}
                >
                  Thought of the Day
                </DashHeading>
              </MenuAndBack>
              <div
                className="d-flex align-items-center justify-content-end"
                style={{ width: "100%" }}
              >
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    background: "#1692B4",
                    border: "#1692B4",
                    width: "150px",
                    height: "50px",
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    setOpenModal(true);
                    setThought({
                      thought: "",
                    });
                  }}
                >
                  ADD THOUGHT
                </button>
              </div>
            </DashboardHeading>

            <Paper
              className={classes.paperTableHeight}
              style={{
                overflow: "hidden",
                height: "100%",
                marginBottom: "0.5rem",
              }}
            >
              <TableContainer className={classes.tableContainerHeight}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tablePadding}
                        style={{ fontWeight: "800" }}
                      >
                        S.&nbsp;No.
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Date
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Thought
                      </TableCell>

                      <TableCell className={classes.tablePadding}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {recordsAfterPagingAndSorting().map((category, index) => (
                      <TableRow key={category.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.textMiddle}
                        >
                          {index + 1 + page * rowsPerPage}
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          {moment(get(category, "date", "")).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>

                        <TableCell
                          className={`${classes.textMiddle} d-flex align-items-center justify-content-center`}
                        >
                          <div style={{ width: "250px", textAlign: "center", height:"50px" }} className="d-flex align-items-center justify-content-center">
                            {category?.thought.length > 10 ? (
                              <MoreLess
                                style={{ width: "100%", textAlign: "center" }}
                                desc={category?.thought}
                              />
                            ) : (
                              <div>{category?.thought}</div>
                            )}
                          </div>
                        </TableCell>

                        <TableCell>
                          <div>
                            <Tooltip title={"Delete"} arrow>
                              <Button
                                // variant="outlined"
                                aria-label="delete"
                                // className={classes.Marginbutton}
                                onClick={() => {
                                  deleteThought({ categoryId: category.id });
                                }}
                              >
                                <DeleteOutline />
                              </Button>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {tableData.length === 0 ? (
                <Nodata TextToDisplay="No Data Found." fontSize="24px" />
              ) : (
                false
              )}
              <TablePagination
                className={classes.tablePaginationStyle}
                rowsPerPageOptions={[15, 30, 100]}
                component="div"
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </DashboardWrapper>
        </DashboardContainer>
      </div>

      <Modal
        maxWidth="lg"
        width="500px"
        height="auto"
        RoundedCorners={true}
        isOpen={openModal}
        // RoundedCorners={true}
        onClose={(event, reason) => {
          if (reason && (reason === "backdropClick" || "escapeKeyDown")) {
          } else {
            setOpenModal(false);
            setThought({
              thought: "",
            });
          }
        }}
        backgroundModal={false}
        backgroundModalContent={false}
        title={
          <div>
            <div
              className="my-3"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "22px",
                fontFamily: "'DM Sans', sans-serif",
              }}
            >
              {"Add New Thought"}
            </div>
            <div className="">
              <SlClose
                style={{
                  position: "absolute",
                  top: 16,
                  right: 16,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenModal(false);
                  setThought({
                    thought: "",
                  });
                }}
              />
            </div>
          </div>
        }
        content={
          <>
            <Formik
              key={thought}
              enableReinitialize
              initialValues={thought}
              validate={thoughtValidator}
              validateOnChange
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {(formikBag) => {
                return (
                  <Form>
                    {console.log(formikBag.values)}
                    <div className="signup-cont">
                      <div className="row">
                        <div className="col-md-12">
                          <label className={classes.offerLabel}>
                            Thought of the day
                          </label>
                          <Field name="Enter Thought">
                            {({ field }) => (
                              <div className="pb-2 mt-1">
                                <Input
                                  {...field}
                                  type="text"
                                  variant="outlined"
                                  autocomplete="off"
                                  value={formikBag.values.thought}
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "thought",
                                      e.target.value
                                    );
                                  }}
                                  error={
                                    formikBag.touched.thought &&
                                    formikBag.errors.thought
                                      ? formikBag.errors.thought
                                      : null
                                  }
                                  className="form-control"
                                  placeholder="Thought of the day"
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div
                        className="col-md-12"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <HeadingButton
                          type="submit"
                          style={{ padding: "0.5em 2em" }}
                        >
                          Save
                        </HeadingButton>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </>
        }
      />
      {isLoading && <Overlay />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OfferManagement));
